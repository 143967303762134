import { Injectable } from '@angular/core';
import { GlobalService } from './global.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  url: string = this.globalService.url;
  header = this.globalService.getHeader();
  constructor(private globalService: GlobalService, private http: HttpClient) {}

  /**
   * Import Users
   */
  importUsers(obj) {
    return this.http.post(
      `${this.url}/v1/admin/master-data/imports/sale-reps`,
      obj,
      {
        headers: {
          Authorization: localStorage.getItem('token'),
        },
      }
    );
  }

  /**
   * Export Template User
   */
  exportTemplate() {
    return this.http.get(
      `${this.url}/v1/admin/master-data/exports/sale-reps?limit=0`,
      {
        headers: this.header,
        responseType: 'blob',
      }
    );
  }

  /**
   * Export Error Logs User
   */
  exportErrorLogs() {
    return this.http.get(
      `${this.url}/v1/admin/master-data/exports/sale-reps/errors`,
      {
        headers: this.header,
        responseType: 'blob',
      }
    );
  }

  /**
   * Export All User
   */
  exportAll(params) {
    return this.http.get(`${this.url}/v1/admin/master-data/exports/sale-reps`, {
      headers: this.header,
      params: params,
      responseType: 'blob',
    });
  }

  /**
   * Get User Detail API
   * @param id
   */
  getUser(id) {
    return this.http.get(`${this.url}/v1/admin/sale-reps/${id}`, {
      headers: this.header,
    });
  }

  /**
   * Get Sales  API
   */
  getSales(limit = 100, offset = 0) {
    const params = {
      limit: `${limit}`,
      offset: `${offset}`,
      roleCode: 'sr',
      status: 'active',
    };
    return this.http.get(
      `${this.url}/v1/admin/sale-reps/drop-down-by-manager`,
      {
        headers: this.header,
        params,
      }
    );
  }

  /**
   * Delete User API
   * @param id
   */
  InactiveUser(id) {
    return this.http.delete(`${this.url}/v1/admin/sale-reps/${id}`, {
      headers: this.header,
    });
  }

  /**
   * Active User API
   * @param body
   */
  activeUser(body, id) {
    return this.http.put(
      `${this.url}/v1/admin/sale-reps/${id}/re-active`,
      body,
      {
        headers: this.header,
      }
    );
  }

  /**
   * Get all User API
   */
  getUsers(params) {
    const url = `${this.url}/v1/admin/sale-reps`;

    return this.http.get(url, {
      headers: this.header,
      params: params,
    });
  }

  /**
   * Get Roles API
   */
  getUserRoles() {
    return this.http.get(`${this.url}/v1/admin/roles?type=sale`, {
      headers: this.header,
    });
  }

  /**
   * Get Region API
   */
  getRegions() {
    return this.http.get(`${this.url}/v1/admin/regions?status=active`, {
      headers: this.header,
    });
  }

  /**
   * Get Cities by Region Id API
   * @param id
   */
  getCities(id) {
    return this.http.get(
      `${this.url}/v1/admin/cities?status=active&regionId=${id}&all=1`,
      {
        headers: this.header,
      }
    );
  }

  /**
   * Get Districts by City Id API
   * @param id
   */
  getDistricts(id) {
    return this.http.get(
      `${this.url}/v1/admin/districts?status=active&cityId=${id}&all=1`,
      {
        headers: this.header,
      }
    );
  }

  /**
   * Get Communes by District Id API
   * @param id
   */
  getCommunes(id) {
    return this.http.get(
      `${this.url}/v1/admin/communes?status=active&districtId=${id}&all=1`,
      {
        headers: this.header,
      }
    );
  }

  /**
   * Get Manager by User Role API
   * @param code
   */
  getManagers(code) {
    return this.http.get(
      `${this.url}/v1/admin/sale-reps/managers?roleCode=${code}&all=1`,
      {
        headers: this.header,
      }
    );
  }

  /**
   * Add New User API
   * @param body
   */
  createUser(body) {
    return this.http.post(`${this.url}/v1/admin/sale-reps`, body, {
      headers: this.header,
    });
  }

  /**
   * Update User API
   * @param body
   */
  editUser(body, id) {
    return this.http.put(`${this.url}/v1/admin/sale-reps/${id}`, body, {
      headers: this.header,
    });
  }

  /**
   * Reset Password
   */
  resetPassword(id) {
    const body = {};
    return this.http.put(
      `${this.url}/v1/admin/users/${id}/clear-password`,
      body,
      {
        headers: this.header,
      }
    );
  }

  /**
   * Start Agent Service
   */
  getDistributors(limit, offset) {
    return this.http.get(
      `${this.url}/v1/admin/distributors/drop-down?limit=${limit}&offset=${offset}&status=active`,
      {
        headers: this.header,
      }
    );
  }

  /**
   * Start Agent Service
   */
  pushToSap(saleRepId: string) {
    return this.http.post(
      `${this.url}/v1/admin/sale-reps/re-push/${saleRepId}`,
      {},
      {
        headers: this.header,
      }
    );
  }
}
