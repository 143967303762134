import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { UsersService } from 'src/app/services/users.service';
import { SaleDropdown } from 'src/app/shared/models/sale';

@Component({
	selector: 'app-sales-dropdown',
	templateUrl: './app-sales-dropdown.component.html',
	styleUrls: ['./app-sales-dropdown.component.scss']
})
export class AppSalesDropdownComponent implements OnInit {
	sales: SaleDropdown[];
	limit = 100;
	offset = 0;
	totals: number;
	saleReps: any[];
	selectedSale = null;
	saleReps1: any[] = [];
	addNameAllToSaleReps = true;
	numberOfItemsFromEndBeforeFetchingMore = 5;

	@Input() saleRepId = null;
	@Output() saleChange = new EventEmitter();

	constructor(private usersService: UsersService) {}

	ngOnInit() {
		this.getSales();
	}

	/**
	 * Get Sales Dropdown
	 */
	getSales() {
		this.usersService
			.getSales(this.limit, this.offset)
			.subscribe((res: any) => {
				this.sales = res.data;
				this.totals = res.metadata.total;
				this.saleReps = [];
				// check old data and push to filter
				if (this.saleReps1.length) {
					this.saleReps1.forEach(i => {
						this.saleReps.push({
							name: i.name,
							id: i.id
						});
					});
				}
				// push new data to filter
				if (this.sales.length) {
					this.sales.forEach(i => {
						this.saleReps.push({
							name: i.fullName,
							id: i.id
						});
					});
				}
				// set default value in filter
				if (this.addNameAllToSaleReps) {
					this.saleReps.unshift({
						name: 'All',
						id: null
					});
				}
				this.addNameAllToSaleReps = false;
				// store old data
				this.saleReps1 = [];
				if (this.saleReps.length) {
					this.saleReps.forEach(i => {
						this.saleReps1.push({
							name: i.name,
							id: i.id
						});
					});
				}
				// set value in filter
				if (this.saleRepId) {
					this.selectedSale = this.saleRepId;
				} else if (this.saleReps.length) {
					this.selectedSale = this.saleReps[0].id;
				}
			});
	}

	/*********************************
	 * Start Get Sale Officers on Scroll *
	 *********************************/
	onScrollToEnd() {
		this.fetchMore();
	}

	onScroll({ end }) {
		if (this.saleReps.length >= this.totals) {
			return;
		}

		if (end + this.numberOfItemsFromEndBeforeFetchingMore >= this.totals) {
			this.fetchMore();
		}
	}

	private fetchMore() {
		this.offset = this.offset + this.limit;
		if (this.saleReps.length >= this.totals) {
			return;
		}
		this.getSales();
	}
	/**********************************
	 ** End Get Sale Officers on Scroll  **
	 **********************************/

	/**
	 * Filter Customers by Sales
	 * @param event
	 */
	onSaleChange(event) {
		this.saleChange.next({
			saleId: event.id
		});
	}
}
