import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DropdownFilterService } from 'src/app/services/index-service';
import { arrayToPipeString } from '../../helper/custom-function';

@Component({
	selector: 'app-sr-filter',
	template: `
		<app-select-filter 
			[hasIcon] = "hasIcon"
			bindLabel = "name"
			bindValue = "id"
			placeholder = "Select Sale Officer"
			label = "Sale Officer"
			[clearable]="multiple"
			[multiple] = "multiple"
			[items] = "metadata.data"
			[(value)] = value
			(valueChange)="valueChange.emit(value)"
		>
		</app-select-filter>
    `,
})

export class AppSRFilterComponent implements OnInit {
	@Input() params: Object = {};
	@Input() multiple = false;
	@Input() value: any = null;
	@Output() valueChange = new EventEmitter();
	@Input() sdIds = null;
	@Input() rsmIds = null;
	@Input() asmIds = null;
	@Input() hasIcon = false;
	metadata = {
		data: [],
		limit: 100,
		total: 0,
		search: '',
	}
	constructor( private dropdownFilter: DropdownFilterService ) { }

	ngOnInit() {
		this.getSRs();
	}

	getSRs() {
		let mtd = this.metadata;
		if (!mtd.data.length || mtd.data.length < mtd.total) {
			const params = {
				status: 'active',
				limit: mtd.limit,
				offset: mtd.data.length,
				sdIds: arrayToPipeString(this.sdIds),
				rsmIds: arrayToPipeString(this.rsmIds),
				asmIds: arrayToPipeString(this.asmIds),
				...this.params,
			};
			this.dropdownFilter
				.getSRList(params)
				.subscribe((res: any) => {
					const mappedData = (res.data || []).map(d => {
						return {
							id: d.id,
							name: d.fullName,
						}
					})
					mtd.data = [...mtd.data, ...mappedData];
					mtd.total = res.metadata.total;

					if (mtd.data.length && mtd.data.length < mtd.total) this.getSRs();
					else {
						if (!this.multiple) {
							mtd.data.unshift({
								id: '',
								name: 'All',
							});
							this.value = this.value || mtd.data[0].id;
						}
					}
				});
		}
		
	}

	filter() {
		this.value = null;
		this.metadata.data = [];
		this.getSRs();
	}

	refresh() {
		this.metadata.data = [];
		this.getSRs();
	}
}