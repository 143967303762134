import { Injectable } from '@angular/core';
import { GlobalService } from '../global.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
	providedIn: 'root',
})
export class WarehouseService {
	header = this.globalService.getHeader();
	url: string = this.globalService.url;

	constructor(private globalService: GlobalService, private http: HttpClient) { }

	/**
	 * Get Warehouse List
	 * @param limit
	 * @param offset
	 * @param param
	 */
	getWarehouses(limit, offset, param) {
		return this.http.get(
			`${this.url}/v2/admin/sap/soap/warehouse?limit=${limit}&offset=${offset}${param}`,
			{
				headers: this.header,
			}
		);
	}

	/**
	 * Get Warehouse Detail
	 * @param id
	 */
	getWarehouse(id) {
		return this.http.get(`${this.url}/v2/admin/sap/soap/warehouse/id/${id}`, {
			headers: this.header,
		});
	}

	getSAPInventory(params) {
		return this.http.get(
			`${this.url}/v1/sap/soap/products/stock`,
			{
				headers: this.header,
				params: this.globalService.cleanParams(params),
			}
		);
	}

	toggleSAPProductStatus(id: number) {
		return this.http.post(
			`${this.url}/v1/sap/soap/products/stock/${id}/toggle`, {},
			{ headers: this.header }
		);
	}

	exportAllSAPInventory(params) {
		return this.http.get(
			`${this.url}/v1/admin/inventory/export-sap-inventory`,
			{
				headers: this.header,
				params: this.globalService.cleanParams(params),
				responseType: 'blob'
			}
		);
	}

	/// Get Own Agents For Dropdown.
	getDist(limit = 100, offset = 0) {
		const params = {
			limit: `${limit}`,
			offset: `${offset}`,
			status: 'active',
		};
		return this.http.get(
			`${this.url}/v1/admin/distributors/drop-down`,
			{
				headers: this.header,
				params,
			}
		);
	}
}
