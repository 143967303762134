import { Injectable } from '@angular/core';
import { GlobalService } from './global.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  header = this.globalService.getHeader();
  url: string = this.globalService.url + '/v1/admin';

  constructor(private globalService: GlobalService, private http: HttpClient) {}
  /**
   * Get Order Summary Report
   * @param link
   * @param limit
   * @param offset
   * @param param
   */
  getReports(link, limit, offset, param) {
    return this.http.get(
      `${this.url}/reports/${link}?limit=${limit}&offset=${offset}${param}`,
      {
        headers: this.header,
      }
    );
  }

  /**
   * Export Reports
   * @param type
   * @param limit
   * @param param
   */
  exportReports(type, limit, param) {
    return this.http.get(
      `${this.url}/reports/exports/${type}?limit=${limit}${param}`,
      {
        headers: this.header,
        responseType: 'blob',
      }
    );
  }

  /**
   * Export Reports V2
   * @param type
   * @param limit
   * @param param
   */
  exportReportByPath(type, limit, param) {
    return this.http.get(
      `${this.url}/reports/exports/${type}?limit=${limit}${param}`,
      {
        headers: this.header,
      }
    );
  }

  exportReportByPathObjParam(type, param) {
    return this.http.get(
      `${this.url}/reports/exports/${type}`,
      {
        headers: this.header,
        params: this.globalService.cleanParams(param)
      }
    );
  }

  /**
   * Get Path File from API
   */
  getPathFiles(filename) {
    return this.http.get(`${this.url}/reports/file?filename=${filename}`, {
      headers: this.header,
    });
  }

  /**
   * Get Delivery Status Open Report
   * @param limit
   * @param offset
   * @param param
   */
  getStockCount(limit, offset, param) {
    return this.http.get(
      `${this.url}/reports/stock-count?limit=${limit}&offset=${offset}${param}`,
      {
        headers: this.header,
      }
    );
  }

  /**
   * Get Stock Count Header
   * @param limit
   */
  getStockCountHeader() {
    return this.http.get(
      `${this.url}/stock-containers/headers?limit=100&status=active`,
      {
        headers: this.header,
      }
    );
  }

  /**
   * Get KPI Sale Volume Header
   * @param limit
   */
  getKPIHeader() {
    return this.http.get(
      `${this.url}/products/brand-packsize?limit=100&status=active`,
      {
        headers: this.header,
      }
    );
  }

  /**
   * Get Delivery Status Open Report
   * @param limit
   * @param offset
   * @param param
   */
  getDeliveryOpen(limit, offset, param) {
    return this.http.get(
      `${this.url}/reports/delivery-report-open?limit=${limit}&offset=${offset}${param}`,
      {
        headers: this.header,
      }
    );
  }

  /**
   * Get Delivery Status Close Report
   * @param limit
   * @param offset
   * @param param
   */
  getDeliveryClose(limit, offset, param) {
    return this.http.get(
      `${this.url}/reports/delivery-report-close?limit=${limit}&offset=${offset}${param}`,
      {
        headers: this.header,
      }
    );
  }

  getDeliveryCloseObjParams(limit, offset, param) {
    return this.http.get(
      `${this.url}/reports/delivery-report-close?limit=${limit}&offset=${offset}`,
      {
        headers: this.header,
        params: this.globalService.cleanParams(param)
      }
    );
  }

  getDeliveryCloseOpen(limit, offset, params) {
    return this.http.get(
      `${this.globalService.url}/v1/sap/soap/orders/deliveries/close-open?limit=${limit}&offset=${offset}${params}`,
      {
        headers: this.header,
      }
    );
  }

  /**
   * Get Non Hit Rate Report
   * @param limit
   * @param offset
   * @param param
   */
  getRouteHitRate(limit, offset, param) {
    return this.http.get(
      `${this.url}/reports/route-hit?limit=${limit}&offset=${offset}${param}`,
      {
        headers: this.header,
      }
    );
  }

  /**
   * Get Non Hit Rate Report
   * @param limit
   * @param offset
   * @param param
   */
  getNonHitRate(limit, offset, param) {
    return this.http.get(
      `${this.url}/reports/no-order?limit=${limit}&offset=${offset}${param}`,
      {
        headers: this.header,
      }
    );
  }

  /**
   * Get Order Summary Report
   * @param limit
   * @param offset
   * @param param
   */
  getOrderSummary(limit, offset, param) {
    return this.http.get(
      `${this.url}/reports/order-summary?limit=${limit}&offset=${offset}${param}`,
      {
        headers: this.header,
      }
    );
  }

  /**
   * Get Agents
   */
  getDistributorsDropDown() {
    return this.http.get(
      `${this.url}/distributors/drop-down?limit=100&status=active`,
      { headers: this.header }
    );
  }

  /***** SAP Management Report *****/
  getSAPManagement(params) {
    return this.http.get(this.globalService.url + '/v1/sap/soap/orders/deliveries/management-reports',
      {
        headers: this.header,
        params: this.globalService.cleanParams(params)
      }
    );
  }
  exportSAPManagement(params) {
    return this.http.get(this.globalService.url + '/v1/admin/reports/exports/sap-management',
      {
        headers: this.header,
        params: this.globalService.cleanParams(params),
      }
    );
  }

    /***** Secondary Transaction Based Report *****/
    getSecondaryTransactionBased(params) {
        return this.http.get(this.globalService.url + '/v1/admin/reports/secondary-transaction-based-report',
            {
                headers: this.header,
                params: this.globalService.cleanParams(params)
            }
        );
    }
    exportSecondaryTransactionBased(params) {
        return this.http.get(this.globalService.url + '/v1/admin/reports/exports/secondary-transaction-based-report',
            {
                headers: this.header,
                params: this.globalService.cleanParams(params),
            }
        );
    }

    /***** User Access Report *****/
    getUserAccessLog(params) {
        return this.http.get(this.globalService.url + '/v1/admin/reports/user-access',
            {
                headers: this.header,
                params: this.globalService.cleanParams(params)
            }
        );
    }
    exportUserAccessLog(params) {
        return this.http.get(this.globalService.url + '/v1/admin/reports/exports/user-access',
            {
                headers: this.header,
                params: this.globalService.cleanParams(params),
            }
        );
    }

    /***** Visit Report – Sale Officer *****/
    getSaleVisit(params) {
        return this.http.get(this.globalService.url + '/v1/admin/reports/visit-time-tracking-summary',
        {
            headers: this.header,
            params: this.globalService.cleanParams(params)
        }
        );
    }
    exportSaleVisit(params) {
        return this.http.get(this.globalService.url + '/v1/admin/reports/exports/visit-time-tracking-summary',
        {
            headers: this.header,
            responseType: 'blob',
            params: this.globalService.cleanParams(params),
        }
        );
    }
}
