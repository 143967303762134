import { Injectable } from '@angular/core';
import { Profile } from '../shared/reducers/model/profile.model';
import { CurrentUserService } from './current-user.service';

@Injectable({
	providedIn: 'root'
})
export class AccessPermissionService {
	profile: Profile;

	constructor(private cUser: CurrentUserService) { }

	/***** Check current user has passed permission code. */
	hasPermissions(permissionCodes: string[]) {
		const bool = permissionCodes.some(r => this.cUser.moduleCodes.indexOf(r) >= 0 || r == 'GENERAL');
		return bool;
	}

	get accessModules() {
		return [
			/***** Dashboard *****/
			{
				text: 'Admin Dashboard',
				visible: this.hasPermissions(['dashboard-all','dashboard', 'dashboard:admin', 'dashboard:sale-rep']),
				url: '/dashboard'
			},
			{
				text: 'PMI Dashboard',
				visible: this.hasPermissions(['dashboard-all','dashboard-pmi']),
				url: '/brand-dashboard'
			},
			/***** Orders *****/
			{
				text: 'Primary Orders',
				visible: this.hasPermissions(['order-primary','order-primary:view']),
				url: '/primary-orders'
			},
			{
				text: 'Orders',
				visible: this.hasPermissions(['order','order:view']),
				url: '/orders'
			},
			{
				text: 'Delivery Routes',
				visible: this.hasPermissions(['delivery-route','delivery-route:view']),
				url: '/route'
			},
			/***** Inventories *****/
			{
				text: 'SAP Inventory',
				visible: this.hasPermissions(['inventory:sap','inventory:sap:view']),
				url: '/inventory/sap-inv'
			},
			{
				text: 'Agent Price List',
				visible: this.hasPermissions(['inventory:distributor','inventory:distributor:view']),
				url: '/inventory/distributor-price-list'
			},
			{
				text: 'Stock Adjustment',
				visible: this.hasPermissions(['inventory:stock-adjustment','inventory:stock-adjustment:view']),
				url: '/inventory/stock-adjustment'
			},
			{
				text: 'Goods Receive',
				visible: this.hasPermissions(['inventory:good-receive','inventory:good-receive:view']),
				url: '/inventory/goods-receive'
			},
			{
				text: 'Count',
				visible: this.hasPermissions(['inventory:count','inventory:count:view']),
				url: '/inventory/count'
			},
			{
				text: 'Target Stock',
				visible: this.hasPermissions(['inventory:target-stock','inventory:target-stock:view']),
				url: '/inventory/target-stock'
			},
			/***** Users *****/
			{
				text: 'Users',
				visible: this.hasPermissions(['user','user:view']),
				url: '/users'
			},
			/***** Customer *****/
			{
				text: 'Agent',
				visible: this.hasPermissions(['customer:distributor','customer:distributor:view']),
				url: '/distributor'
			},
			{
				text: 'Customer',
				visible: this.hasPermissions(['customer:customer','customer:customer:view']),
				url: '/customer'
			},
			{
				text: 'Customer Location',
				visible: this.hasPermissions(['customer:customer-location','customer:customer-location:view']),
				url: '/customer-location'
			},
			{
				text: 'Customer Group',
				visible: this.hasPermissions(['customer:customer-group','customer:customer-group:view']),
				url: '/customer-group'
			},
			/***** Market Survey *****/
		]
	}

}
