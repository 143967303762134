import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CurrentUserService } from 'src/app/services/current-user.service';
import { DropdownFilterService } from 'src/app/services/index-service';
import { arrayToPipeString } from '../../helper/custom-function';

@Component({
	selector: 'app-distributor-filter',
	template: `
		<app-select-filter 
			[disabled]="cUser.isDistributor() || disabled"
			[hasIcon] = "hasIcon"
			bindLabel = "name"
			bindValue = "id"
			placeholder = "Select Agent"
			[label] = "label"
			[multiple] = "multiple"
			[items] = "metadata.data"
			[(value)] = value
			(onChange)="changeFilter()"
			[clearable]="multiple"
		>
		</app-select-filter>
    `,
})

export class AppDistributorFilterComponent implements OnInit {
	@Input() disabled = false;
	@Input() value: any = null;
	@Output() valueChange = new EventEmitter();
	@Output() onFilter = new EventEmitter();
	@Input() hasIcon = false;
	@Input() isSAPDistributorOnly = false;
	@Input() isSecondaryDistributorOnly = false;
	@Input() hasAllOption = true;
	@Input() multiple = false;
	@Input() label = 'Agent';
	@Input() regionIds: number[] = null;
    @Input() regionalIds: number[] = null;
	@Input() hasDefault = false;
	metadata = {
		data: [],
		limit: 100,
		total: 0,
		search: '',
	}
	constructor(private dataService: DropdownFilterService, public cUser: CurrentUserService,) { }

	ngOnInit() {
		this.getDistributors();
	}

	getDistributors() {
		let mtd = this.metadata;
		/***** If current user is Agent, set default value *****/
		if (this.cUser.isDistributor()) {
			if (this.multiple) this.value = [this.cUser.profile.id];
			else this.value = this.cUser.profile.id;
			mtd.data = [
				{
					id: this.cUser.profile.id,
					name: this.cUser.profile.name
						? this.cUser.profile.name
						: `${this.cUser.profile.firstName} ${this.cUser.profile.lastName}`,
					distributorCode: this.cUser.profile.userCode,
				},
			];
			this.finish();
			/***** else, retrive normally from API *****/
		} else {
			if (!mtd.data.length || mtd.data.length < mtd.total) {
				const params = {
					limit: mtd.limit,
					offset: mtd.data.length,
					billTo: this.isSAPDistributorOnly ? 'company' : this.isSecondaryDistributorOnly ? 'distributor' : '',
					regionIds: arrayToPipeString(this.regionIds),
					regionalIds: arrayToPipeString(this.regionalIds), 
					sortBy:'name|asc'
				}
				this.dataService
					.getDistributors(params)
					.subscribe((res: any) => {
						const mappedData = (res.data || []).map(d => {
							return {
								id: d.distributorId,
								name: d.fullName,
							}
						})
						mtd.data = [...mtd.data, ...mappedData];
						mtd.total = res.metadata.total;

						if (mtd.data.length && mtd.data.length < mtd.total) this.getDistributors();
						else {
							if (this.hasAllOption && !this.multiple) {
								mtd.data.unshift({
									id: '',
									name: 'All',
								});
							}

							if (this.multiple && this.hasDefault) {
								this.value = [mtd.data[0].id];
							}
							
							this.finish();
						}
					});
			}
		}
	}

	changeFilter() {
		this.valueChange.emit(this.value);
		this.onFilter.emit({ value: this.value, isFilter: true });
	}

	finish() {
		this.value = this.value ? this.value : this.metadata.data && this.metadata.data.length ? this.metadata.data[0].id : null;
		this.valueChange.emit(this.value);
		this.onFilter.emit({ value: this.value, isFilter: false });
	}

	refresh() {
		setTimeout(() => {
			this.value = null;
			this.metadata.data = [];
			this.getDistributors();
		}, 500);
	}
}